import React, { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { motion } from 'framer-motion';
import MetaTags from '../../components/MetaTags';
import { theme } from '../../styles/theme';
import Background from '../../components/Background';
import Header from '../../components/Header2';
import HowGroupGiftsWork from '../../sections/occasion/HowGroupGiftsWork';
import CuratedGifts from '../../sections/occasion/CuratedGifts';
import GroupCard from '../../sections/occasion/GroupCard';
import LoveIllume from '../../sections/occasion/LoveIllume';
import JoinOccasions from '../../sections/JoinOccasions';
import Footer from '../../sections/common/FooterWithNav';
import data from '../../sections/occasion/data';
import futureCelebrating from '../../images/future-of-celebrating.svg';
import futurePhone from '../../images/occasion/iPhone12.png';
import HeaderSection from '../../sections/occasion/HeaderSection';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const OccassionMothersDay: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='Occasions | illume'
        description={'Create beautiful, meaningful Mother’s Day ecards and gifts with illume.'}
      />
      <Layout>
        <Background showTopBlob={false} />
        <Header />
        <HeaderSection
          heading={"Mother's Day Gifts That Stand Out"}
          paragraphs={[
            'Anna and Ryan live on opposite coasts and don’t know what to do for Mother’s Day. Since they can’t be together, they decide to send Mom a group gift with illume.',
            'Anna picks out a gift from illume’s digital marketplace, invites Ryan (plus Grandma and Auntie Lisa) to contribute with cash, messages, photos, and more, and then schedules their group gift to deliver on Mother’s Day. Mom wakes up to a beautiful surprise — that easy.',
          ]}
          buttonLink={'https://link.illumenotes.com/JNfqmvPdEATzVwQN7'}
          images={{
            celebration: {
              image: futureCelebrating,
              label: 'Mothers Day',
            },
            phone: {
              image: futurePhone,
              label: 'Mothers Day Mobile',
            },
          }}
        />
        <HowGroupGiftsWork />
        <CuratedGifts gifts={data.gifts} />
        <GroupCard />
        <LoveIllume testimonials={data.testimonials} />
        <JoinOccasions 
            joinMessage={'of celebrating Mother\'s Day'}
        />
        <Footer links={data.links} />
      </Layout>
    </ThemeProvider>
  );
};

export default OccassionMothersDay;
